.sign-in.graphic img {
    max-width: 90%;
}
.signin-div .logo-sgnin {
	max-width: 340px;
	margin-bottom: 40px;
}
.signin-form .card.p-4 {
	margin: 40px 0 0 19px;
	padding: 40px 30px 40px !important;
	border-radius: 23px;
	background-color: #eff1f5;
	border: 0px;
}
.signin-form h1 {
	color: #394584;
	font-size: 32px;
	margin-bottom: 20px;
}
.signin-form label {
	font-size: 16px;
	color: #394584;
}
.signin-form .form-control {
	padding: 15px !important;
	border-color: #bfc3d6 !important;
	background-color: #f8f9fb !important;
}
.signin-form label.label-small.label-forget {
	font-size: 14px;
    margin-top:10px;
}
.login-form-extend .container {
	max-width: 85%;
}
.button {
	padding: 10px 30px;
	background-color: #2e4dd4;
	color: #fff;
	border: 0px;
	border-radius: 4px;
	font-size: 17px;
	font-weight: 600;
}
.wt-80 {
	width: 80%;
}
.btn-bigger {
    padding: 15px !important;
    width: 100%;
    margin-top: 12px;
}
.col-md-12.contact-admin h4 {
	border-bottom: 2px solid #2e4dd4;
	line-height: 45px;
	width: 125px;
	color: #2e4dd4;
	font-size: 18px;
	font-weight: 600;
	float: none;
	margin: auto;
}
.col-md-12.contact-admin {
	padding-top: 20px;
}
.ant-modal-wrap.ant-modal-centered {
	z-index: 9999;
}