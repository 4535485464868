@import url('./font.css');

.sidebar-brand.d-none.d-md-flex {
	display: flex !important;
	flex-wrap: wrap;
	background-color: #fff;
	border-right: 1px solid #ddd;
}

.logoArea.logo-brand img {
	max-width: 200px;
	max-height: 40px;
}

.nav-item .welcome-fnt,
.nav-item .welcome-fnt strong {
	font-weight: 400 !important;
	/* font-family: var(--cui-font-sans-serif); */
	font-family: 'Switzer';
	font-size: 16px;
	margin-bottom: 0px;
	line-height: 35px;
}

.card-body .fs-4 {
	font-size: 1.3rem !important;
	color: #000;
}

.show-scroll .track-vertical2 {
	right: 0px;
	top: 0px;
	bottom: 0px;
	background-color: #e9e4e4;
	border-radius: 8px;
}

.show-scroll .thumb-vertical2 {
	background: rgba(0, 0, 0, 0.3);
	border-radius: 8px;
}

.submenu.show-scroll-active .track-vertical2 {
	opacity: 1 !important;
	visibility: visible;
}

.submenu.show-scroll-active .thumb-vertical2 {
	opacity: 1 !important;
	visibility: visible;
}

.card-body .fs-6.fw-normal {
	margin-left: 10px;
}

.eq-ht-160 .card {
	min-height: 60px;
}

.submenu .down-icon {
	text-align: center;
}

.submenu .down-icon img {
	max-width: 20px;
	cursor: pointer;
}

/* .spacing-for-tag .count {
	color: #fff;
	background-color: #04e06e;
	width: 24px;
	height: 25px;
	display: inline-block;
	border-radius: 50%;
	font-size: 10px;
	line-height: 24px;
} */
.nav-link img {
	max-width: 20px;
	margin-right: 10px;
	height: auto !important;
}

.ant-table-body {
	overflow: hidden;
}

.spacing-for-tag .left-col,
.spacing-for-tag .right-col {
	width: 48%;
	float: left;
	padding: 10px;
	background: #155839;
	border-radius: 25px;
	max-width: 48%;
	color: #fff;
	text-align: center;
}

.col-container {
	/* display: table; */
	height: 100%;
}

.location-card-active {
	color: #fff;
}

.full_height {
	height: 90%;
	margin-bottom: 20px;
}

.col {
	display: table-cell;
}

.temp_card_padding {
	margin: 10px;
	align-items: center;
}

.login_spacing_component {
	margin-top: 20px;
}

.dangerColor {
	color: red;
}

.center_align {
	align-items: center;
}

.right_align {
	align-items: right;
}

.temp_card_padding .right-col {
	align-content: flex-end;
}

.temp_font {
	color: #000;
	font-size: 25px;
}

.sub_font_parent {
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
}

.sub_font {
	color: #000;
	font-size: 14px;
}

.sub_font_right_align {
	text-align: end;
}

.medium_font_with_padding {
	color: #000;
	font-size: 18px;
	padding-left: 15px;
}



.spacing-for-button {
	width: 75%;
	float: center;
	padding: 10px;
	margin: 10px;
	background: #5ebec3;
	border-radius: 25px;
	max-width: 75%;
	color: #fff;
	align-items: center;
	align-self: center;
	text-align: center;
}

.Online-devices,
.offline-devices {
	padding-left: 15px;
	padding-right: 15px;
	color: #1d975e;
	position: relative;
	margin-left: 10px;
	cursor: pointer;
}

.Online-devices::before,
.offline-devices::before {
	content: '';
	position: absolute;
	left: 5px;
	background: #1d975e;
	width: 6px;
	height: 6px;
	top: 0;
	bottom: 0;
	margin: auto;
	border-radius: 50%;

}

.offline-devices {
	color: #ff0000;
	margin-left: 0px;
}

.offline-devices::before {
	background: #ff0000;
}

.legends_parent.legend-4-color .legends_square {
	background-image: linear-gradient(to right, green, orange, red, purple);
}

.spacing-for-tag .right-col {
	float: right;
	margin-left: 5px;
	background: #e55353;
}

.spacing-for-tag {
	padding: 10px;
}

.ag-header-cell.ag-header-cell-sortable {
	background-color: #3c4b64 !important;
	color: #fff !important;
	/* padding: 8px !important; */
	max-height: 40px;
	font-weight: normal;
}

.ag-icon.ag-icon-desc,
.ag-theme-alpine .ag-header-icon {
	color: #fff !important;
}

.ag-icon.ag-icon-menu {
	color: #fff !important;
}

.ag-header-viewport,
.ag-header.ag-focus-managed.ag-pivot-off {
	min-height: 40px !important;
	max-height: 40px !important;
	background-color: #3c4b64 !important;
}

.tab-button-series .btn {
	margin-right: 10px !important;
}

/* .card.bg-sucess.text-high-emphasis-inverse.mb-4 {

	background-image: linear-gradient(96deg,#04e06e 20%,#04e06e 111%);
} */
.card.bg-sucess.text-high-emphasis-inverse.mb-4 {
	/* background-color: #5ebec3; */
	background-color: #fff;
	border-radius: 10px;
	height: 90%;
	margin-bottom: 20px;
}

.eq-ht-160 {
	border-radius: 10px;
}

.spacing-for-tag .left-col .count {
	display: inline-block;
	border-radius: 50%;
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
	/* color: #155839; */
	color: #fff;
}

.spacing-for-tag .right-col .count {
	display: inline-block;
	border-radius: 50%;
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
	/* color:#e55353; */
	color: #fff;

}

.col-lg-3.col-sm-6.eq-ht-160 .card {
	border: 8px solid transparent !important
}

.col-lg-3.col-sm-6.active.eq-ht-160 .card {
	border: 8px solid #fff !important;
}

.tab-button-series {
	padding-bottom: 15px;
	margin-bottom: 15px;
	border-bottom: 1px solid #ddd;
}

.tabParent .btn.active {
	background-color: #3c4b64;
	color: #fff;
	border-color: #3c4b64;
	border: 1px solid #3c4b64 !important;
	border-radius: 8px !important;
}

.btn.btn-primary,
.ant-btn.ant-btn-primary {
	background-color: #3c4b64 !important;
	border-color: #3c4b64 !important;
}

.tabParent .btn:hover,
.tabParent .btn:active,
.tabParent .btn:focus {
	background-color: transparent !important;
	outline: none !important;
	box-shadow: unset !important;
	color: #3c4b64 !important;
}

.tabParent .btn.active:hover,
.tabParent .btn.active:active,
.tabParent .btn.active:focus {
	background-color: #3c4b64 !important;
	color: #fff !important;
}

.icon-size {
	max-width: 18px;
	position: relative;
	margin-right: 7px;
}

.me-2.icon-dropd-down {
	max-width: 17px;
}

.log-out-icon {
	max-width: 20px;
	top: -1px;
	position: relative;
	margin-right: 5px;
}

.nav-item {
	position: relative;
}

.header-nav .nav-item {
	margin-right: 1rem;
}

.notification-count {
	position: absolute;
	top: -3px;
	background-color: #ca352d;
	font-size: 9px;
	width: 18px;
	height: 18px;
	color: #fff;
	line-height: 18px;
	text-align: center;
	border-radius: 20px;
	right: 0px;
}

.icon-export {
	top: -2px;
}

.nav-item.global-date-picker {
	padding-left: 0px;
	padding-right: 0px;
	margin-left: 0px;
	line-height: 35px;
	cursor: pointer;
	position: relative;
}

.nav-item.global-date-picker .icon.icon-lg {
	position: absolute;
	left: 0px;
	z-index: -1;
	top: 8px;
}

.header.header-sticky.mb-4 .header-nav.ms-3 {

	margin: 0px !important;
}

.header.header-sticky.mb-4 .header-nav.ms-3 .nav-item {
	margin-right: 0px !important;
}

.ant-modal-centered .ant-modal {
	border-radius: 4px !important;
	overflow: hidden;
}

h4,
.h4 {
	font-size: 1.3rem !important;
}

.nav-item.global-date-picker .datepickinput {
	background-color: transparent;
	border: 0px;
	font-size: 14px;
	color: #949aa3;
	font-weight: 500;
	width: 100px;
	padding-left: 40px;
	border: 0px !important;
	cursor: pointer !important;

	box-shadow: unset !important;
}

.nav-item.global-date-picker .datepickinput:focus-visible,
.nav-item.global-date-picker .datepickinput:focus,
.nav-item.global-date-picker .datepickinput:active {
	border: 0px !important;
	box-shadow: unset !important;
	outline: none !important;
}

.ant-table-thead th {
	background-color: #3c4b64 !important;
	color: #fff !important;
	padding: 8px !important;
}

.ant-table-tbody tr:nth-child(2n) {
	background-color: #fafafa;
}

.ant-table-tbody>tr>td {
	padding: 8px;
	text-align: center;
}

.ant-table-tbody>tr>td:first-child {
	text-align: left;
}

.ant-table-tbody>tr>td:nth-of-type(2) {
	text-align: left;
}

.ant-table-row td:first-child {
	border-left: 1px solid #e7e5e5;
}

.ant-table-row td:last-child {
	border-right: 1px solid #e7e5e5;
}

.tb-top-area {
	padding-top: 30px;
	border-top: 2px dashed #ddd;
	padding-bottom: 10px;
}

.tb-top-area .date-select {
	float: right;
	font-size: 16px;
	font-weight: 600;
	line-height: 40px;

}

.eq-ht-160.active .card {
	box-shadow: -7px 2px 19px -12px rgba(0, 0, 0, 0.61);
	-webkit-box-shadow: -7px 2px 19px -12px rgba(0, 0, 0, 0.61);
	-moz-box-shadow: -7px 2px 19px -12px rgba(0, 0, 0, 0.61);
	border: 5px solid #fff;
}

.device-tab {
	cursor: pointer;
}

.card {
	background-color: #fff;
	border: 10px !important;
}

.ant-table-column-sorter-down.active,
.ant-table-column-sorter-up.active {
	color: #fff !important;
}

.ant-modal.modal-add-alert .ant-modal-footer {
	display: none;
}

.ant-modal.modal-add-alert .btn.btn-primary.mb-3 {
	width: 100%;
}

.stats-area-table {
	float: left;
	width: 100%;
}

.tb-top-area h4 {
	font-size: 18px;
	font-weight: 600;
	line-height: 40px;
}

.highcharts-drilldown-axis-label,
.highcharts-drilldown-data-label text,
.highcharts-drilldown-data-label text tspan {
	text-decoration: none !important;
	fill: #000 !important;
	color: #666666 !important;
	cursor: default !important;
	font-size: 11px !important;
	fill: #666 !important;
	font-weight: normal !important;
}

.logoutBtn {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 3%;
	margin: auto;
	max-width: 140px;
	background-color: #4e5b6f;
	padding: 10px;
	text-align: center;
	border-radius: 10px;
	cursor: pointer;
	line-height: 25px;
}

.form-select.float-end {
	margin-right: 20px !important;
}

.tabParent {
	float: left;
	border: 1px solid #ddd;
	border-radius: 15px;
	overflow: hidden;
}

.tabParent .btn {
	margin-right: 0px !important;
	border: 0px !important;
	padding: 10px 13px;
	border-radius: 0px !important;
	position: relative;
	font-size: 12px;
	line-height: 11px;
}

.tabParent .btn::after {
	content: '';
	position: absolute;
	width: 2px;
	height: 20px;
	background: #eaeaea;
	right: 0px;
	top: 10px;
}

.tabParent .btn.active::after,
.tabParent .btn:last-child::after {
	height: 0px;
}

.scroll-one-forth {
	max-width: 275px;
	margin-right: 20px;
	margin-bottom: 40px !important;
	float: left;
}

.scroll-one-forth {
	position: absolute;
}

.legendChart img {
	max-width: 300px;
}

.legendChart {
	margin-bottom: 30px;
}

.dropdown-menu .dropdown-item {
	font-size: 14px;
}

.dropdown-menu .dropdown-item svg {
	max-width: 15px;
}

.ant-modal.modal-logout {
	max-width: 400px;
	border-radius: 8px !important;
	overflow: hidden;
}

.ant-modal.modal-logout .ant-modal-header {
	border: 0px;
}

.ant-modal.modal-logout .ant-modal-header .ant-modal-title {
	font-size: 24px;
	text-align: center;
	text-transform: capitalize;
	line-height: 30px;
	padding: 9px;
	color: #3c4b64;
	font-weight: 00;
}

.ant-table-cell.react-resizable {
	position: relative;
	background-clip: padding-box;
}

.ant-table-cell .react-resizable-handle {
	position: absolute;
	right: -5px;
	bottom: 0;
	z-index: 1;
	width: 10px;
	height: 100%;
	cursor: col-resize;
}

.ant-modal.modal-logout .ant-modal-footer {
	text-align: center;
	padding: 0px;
}

.ant-modal.modal-logout h2 {
	text-align: center;
	margin-bottom: 25px;
}

.ant-modal.modal-logout p {
	text-align: center;
	font-size: 20px;
}

.ant-modal.modal-logout .ant-modal-content {
	border-radius: 8px;
}

.ant-modal.modal-logout .ant-modal-footer .ant-btn {
	padding: 15px 20px;
	height: auto;
	min-width: 100px;
	width: 50%;
	background-color: transparent !important;
	border: 0px !important;
	display: inline-block;
	margin: 0px !important;
	font-size: 20px;
}

.ant-modal.modal-logout .ant-modal-footer .ant-btn.ant-btn-primary {
	border-left: 1px solid #ddd !important;
	color: #3c4b64 !important;
}

.ant-modal.modal-logout .ant-modal-content p {
	margin: 0px;
}

.ant-modal.modal-logout .ant-modal-body {
	padding: 40px;
}

.ant-modal.modal-logout .ant-modal-header .ant-modal-title {
	font-size: 20px;
	text-align: center;
	text-transform: capitalize;
	line-height: 30px;
	padding: 0px 20px;
	color: #3c4b64;
	font-weight: 600;
	text-transform: uppercase;
}

.ant-tabs-card .ant-tabs-nav .ant-tabs-tab {
	border: 0px !important;
	padding: 10px 20px;
	border-radius: 0px !important;
	position: relative;
	font-size: 14px;
	margin: 0px !important;
	background-color: transparent !important;
	color: var(--cui-btn-color, rgba(44, 56, 74, 0.95));
	position: relative;
}

.ant-tabs-nav-list .ant-tabs-tab::after {
	content: '';
	position: absolute;
	width: 2px;
	height: 20px;
	background: #eaeaea;
	right: 0px;
	top: 10px;
}

.ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active::after {
	height: 0px;
}

.help-icon img {
	max-width: 16px;
	position: relative;
	top: -2px;
	margin-left: 5px;
}

.descHelp {
	display: none;
	position: absolute;
	z-index: 100;
	top: 100%;
	max-width: 15rem;
	border-radius: .2rem;
	background-color: #000;
	color: #fff;
	padding: 8px;
	width: 175px;
	left: 0px;
}

.help-icon {
	position: relative;
}

.ant-tabs-nav-list {
	float: left;
	border: 1px solid #ddd;
	border-radius: 15px;
	overflow: hidden;
	background-color: #fff;
}

.ant-tabs-card .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab:hover,
.ant-tabs-card .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab:active,
.ant-tabs-card .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab:focus {
	background-color: transparent !important;
	outline: none !important;
	box-shadow: unset !important;
	color: #3c4b64 !important;
}

.ant-tabs-card .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
	background-color: #3c4b64 !important;
	color: #fff !important;
	border-color: #3c4b64;
	border-radius: 8px !important;
}

.ant-tabs-card .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #fff !important;
}

.device-tab {
	background-color: transparent;
}

.ant-picker.ant-picker-range {
	border: 1px solid var(--cui-form-select-border-color, #b1b7c1) !important;
	color: var(--cui-form-select-color, rgba(44, 56, 74, 0.95)) !important;
}

.ant-picker.ant-picker-range input {
	font-size: 13px !important;
}

.ant-picker-dropdown {
	font-size: 12px;
}

.ant-picker-input>input {
	color: var(--cui-form-select-color, rgba(44, 56, 74, 0.95)) !important;
}

.btn-group.dropdown-menu-thing {
	position: absolute;
	right: 15px;
	background-color: #3c4b64;
	width: 40px;
	height: 40px;
	border-radius: 8px;
	z-index: 99;
}

.container-lg {
	position: relative;
}

.tb-top-area-no-border {
	border: 0px !important;
}

.tb-top-area.tb-top-area-less-space {
	padding: 0px;
	padding-bottom: 10px;
}

.ant-modal.modal-logout .ant-modal-header {
	display: none !important;
}

/* AFter FOnt Change */
* {
	font-family: 'Switzer';
}

body,
html {
	/* font-family: 'Helvetica 45 Light', sans-serif !important; */
	font-family: 'Switzer';

	font-weight: 400 !important;
}

body .fw-semibold {
	font-weight: 400 !important
}

.ant-table,
body,
html {
	font-size: 13px;
	line-height: 1.5em;
}

.ant-table-pagination.ant-pagination {
	padding-right: 1px;
}

[class*='ant-table-body']::-webkit-scrollbar {
	height: 8px;
	width: 8px;
}

[class*='ant-table-body']::-webkit-scrollbar-track {
	background: transparent;
}

[class*='ant-table-body']::-webkit-scrollbar-thumb {
	background-color: rgba(#333, .2);
	border: 3px solid transparent;
	border-radius: 4px;
}

.ant-table-content table {
	max-width: 100% !important;
	width: 100% !important
}


.ant-table-wrapper .ant-pagination {
	/* position: absolute; */
}

.nav-link.alarm-nav .icon.icon-lg {
	position: relative;
	top: 5px;
}

.ant-table-header table,
.ant-table-body table {
	max-width: 100%;
	width: 100%;
	table-layout: auto !important;
}

body .form-select:focus,
body input:focus {
	box-shadow: unset !important;
}

.ant-table-cell .btn.btn-edit {
	background-color: #3c4b64;
	color: #fff;
	text-transform: uppercase;
	font-size: 10px;
	padding: 0px;
	width: 30px;
	height: 30px;
}

.ant-table-cell .btn.btn-delete {
	font-size: 10px;
	text-transform: uppercase;
	background-color: #b0342d;
	color: #fff;
	margin-left: 10px;
	padding: 0px;
	width: 30px;
	height: 30px;
}

.ant-table-cell .btn.btn-edit img,
.ant-table-cell .btn.btn-delete img {
	max-width: 20px;

}

/*Table New Drag */
/* Collection default theme */

.ReactVirtualized__Collection {}

.ReactVirtualized__Collection__innerScrollContainer {}

/* Grid default theme */

.ReactVirtualized__Grid {}

.ReactVirtualized__Grid__innerScrollContainer {}

/* Table default theme */

.ReactVirtualized__Table {}

.ReactVirtualized__Table__Grid {}

.ReactVirtualized__Table__headerRow {
	font-weight: 700;
	text-transform: uppercase;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-align: center;
	align-items: center;
}

.ReactVirtualized__Table__row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-align: center;
	align-items: center;
}

.ReactVirtualized__Table__headerTruncatedText {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
	margin-right: 10px;
	min-width: 0px;
}

.ReactVirtualized__Table__rowColumn {
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
	margin-left: 10px;
}

.ReactVirtualized__Table__sortableHeaderColumn {
	cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}

.ReactVirtualized__Table__sortableHeaderIcon {
	-ms-flex: 0 0 24px;
	flex: 0 0 24px;
	height: 1em;
	width: 1em;
	fill: currentColor;
}

/* List default theme */
.ReactVirtualized__Table {
	display: inline-block;
	border: 2px solid rgba(0, 0, 0, 0.1);
}

.ReactVirtualized__Table__headerColumn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 0;
}

.ReactVirtualized__Table__headerTruncatedText {
	flex: auto;
}

.DragHandle {
	flex: 0 0 16px;
	z-index: 2;
	cursor: col-resize;
	color: #0085ff;
}

.DragHandle:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.DragHandleActive,
.DragHandleActive:hover {
	color: #0b6fcc;
	z-index: 3;
}

.DragHandleIcon {
	flex: 0 0 12px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}


.ReactVirtualized__List {}

.no-border {
	border: 0px;
}

.no-space {
	padding: 0px;
}

.nav-item.global-date-picker .react-datepicker * {
	line-height: 20px;
	font-weight: normal !important;
}

.loaderMiddle {
	display: none;
}

.loading .loaderMiddle {
	position: absolute;
	display: block;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 999;
	background-color: #fff;
	text-align: center;
	top: 70px;
}

.loaderMiddle-inner {
	max-height: 300px;
	position: absolute;
	height: 300px;
	text-align: center;
	left: 0;
	right: 0;
}

.widgetInner .card-body .fs-4 {
	font-size: 15px !important;
}

.loaderMiddle img {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	left: 0;
	right: 0;
}

.modal-device-table .ant-modal-footer {
	display: none;
}

.modal-device-table.ant-modal {
	width: 850px !important;
	max-width: 90% !important;
}

.modal-device-table-online .ant-modal-title::before {
	content: '';
	background-color: #1d975e;
	width: 8px;
	height: 8px;
	position: absolute;
	display: inline-block;
	left: 0px;
	top: 0;
	bottom: 0;
	margin: auto;
	border-radius: 50%;
}

.modal-device-table-online .ant-modal-title {
	position: relative;
	color: #1d975e;
	text-transform: capitalize;
	vertical-align: middle;
	padding-left: 15px;
}

.modal-device-table-offline .ant-modal-title::before {
	content: '';
	background-color: #ff0000;
	width: 8px;
	height: 8px;
	position: absolute;
	display: inline-block;
	left: 0px;
	top: 0;
	bottom: 0;
	margin: auto;
	border-radius: 50%;
}

.modal-device-table-offline .ant-modal-title {
	position: relative;
	color: #ff0000;
	text-transform: capitalize;
	vertical-align: middle;
	padding-left: 15px;
}

.ant-modal.loading .ant-modal-header,
.ant-modal.loading .ant-modal-close {
	display: none;
}

.ant-modal.loading form {
	display: none;
}

.ant-modal .loaderAdd {
	display: none;
}

.ant-modal.loading .loaderAdd {
	text-align: center;
	display: block;
}

.modal-add-alert .sucessMessage {
	display: none;
}

.modal-add-alert.loading-opacity form,
.modal-add-alert.loading-opacity form {
	display: none;
}

.modal-add-alert.loading-opacity .sucessMessage {
	display: none;
}

.modal-add-alert .sucessMessage {
	font-size: 15px;
	background-color: #e2f2e4;
	color: #4a803b;
	padding: 10px 20px;
	border: 1px solid #4a803b;
}

.dropdown-item.active,
.dropdown-item:active {
	color: var(--cui-dropdown-link-hover-color, #475468) !important;
	background-color: var(--cui-dropdown-link-hover-bg, #d8dbe0) !important;
}

html:not([dir="rtl"]) .ant-scrolling-effect .btn-group.float-end.me-3.tabParent.lengend-top {
	z-index: 9;
}

.modal-add-alert.loading-opacity .sucessMessage {
	display: block;
}

html:not([dir="rtl"]) .sidebar-nav .nav-icon:first-child {
	margin-left: 0px !important;
}

.highcharts-spline-series .highcharts-label {
	display: none !important;
}

.configuration .form-floating>label {
	color: #878282;
}

.form-floating.mb-3.error .form-label span.ermsg {
	color: #ae2626;
}

.form-floating.mb-3.error input {
	border-color: #ae2626 !important;
}

.loading.loadiing-160 .loaderMiddle {
	top: 160px;
}

.locationWidget .eq-ht-160 .widgetInner.disable-card,
.locationWidget .col-lg-4 .widgetInner.disable-card {
	overflow: hidden;
	max-height: 225px;
}

body>iframe[style*='2147483647'] {
	display: none;
}

.dangerColor {
	color: #8f2828;
	font-size: 13px;
}

.header.header-sticky {
	z-index: 1 !important;
}

.button[disabled] {
	opacity: .5;
	cursor: not-allowed;
}

.eyeIcon {
	position: absolute;
	width: 30px;
	right: 20px;
	max-width: 20px;
	top: 0;
	bottom: 0;
	margin: auto;
	cursor: pointer;
	z-index: 99999;
}

.eyeIcon img {
	max-width: 24px;
	position: absolute;
	max-height: 30px;
	top: 0;
	bottom: 0;
	margin: auto;
}

.legendChart .legends_parent.legend-8-color span {
	width: 20%;
	font-size: 13px;
}

.legendChart .legends_parent.legend-8-color .legends_square {
	background-image: linear-gradient(to right, #00b14f, #95cb53, #f7ec16, #fdc00f, #ed2124);
}

/* Zoom-in animation */
@keyframes zoomIn {
	0% {
		transform: scale(0.5);
		opacity: 0;
	}

	50% {
		transform: scale(1.1);
		opacity: 0.7;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

/* Bounce animation for icon */
@keyframes bounceIn {
	0% {
		transform: scale(0.5);
		opacity: 0;
	}

	50% {
		transform: scale(1.2);
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

/* Success modal content style */
.success-modal-content {
	animation: zoomIn 0.5s ease-out;
}

/* Success icon animation style */
.success-icon {
	font-size: 3rem;
	color: #59b259;
	animation: bounceIn 0.5s ease-out;
}



.containerAnimation {
	width: 300px;
	margin: 50px auto 20px;
	text-align: center;
}

.sucessModalAnime .modal-header {
	position: absolute;
	border: 0px;
	right: 0;
}


.svg-success {
	display: inline-block;
	vertical-align: top;
	height: 100px;
	width: 100px;
	opacity: 1;
	overflow: visible;
}

@-webkit-keyframes success-tick {
	0% {
		stroke-dashoffset: 16px;
		opacity: 1;
	}

	100% {
		stroke-dashoffset: 31px;
		opacity: 1;
	}
}

@keyframes success-tick {
	0% {
		stroke-dashoffset: 16px;
		opacity: 1;
	}

	100% {
		stroke-dashoffset: 31px;
		opacity: 1;
	}
}

@-webkit-keyframes success-circle-outline {
	0% {
		stroke-dashoffset: 72px;
		opacity: 1;
	}

	100% {
		stroke-dashoffset: 0px;
		opacity: 1;
	}
}

@keyframes success-circle-outline {
	0% {
		stroke-dashoffset: 72px;
		opacity: 1;
	}

	100% {
		stroke-dashoffset: 0px;
		opacity: 1;
	}
}

@-webkit-keyframes success-circle-fill {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes success-circle-fill {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.hide2sec {
	/* opacity: 0; */
	animation: hide 4s;
}

@keyframes hide {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.svg-success .success-tick {
	fill: none;
	stroke-width: 1px;
	stroke: #ffffff;
	stroke-dasharray: 15px, 15px;
	stroke-dashoffset: -14px;
	-webkit-animation: success-tick 450ms ease 1400ms forwards;
	animation: success-tick 450ms ease 1400ms forwards;
	opacity: 0;
}

.svg-success .success-circle-outline {
	fill: none;
	stroke-width: 1px;
	stroke: #7aca7a;
	stroke-dasharray: 72px, 72px;
	stroke-dashoffset: 72px;
	-webkit-animation: success-circle-outline 300ms ease-in-out 800ms forwards;
	animation: success-circle-outline 300ms ease-in-out 800ms forwards;
	opacity: 0;
}

.svg-success .success-circle-fill {
	fill: #7aca7a;
	stroke: none;
	opacity: 0;
	-webkit-animation: success-circle-fill 300ms ease-out 1100ms forwards;
	animation: success-circle-fill 300ms ease-out 1100ms forwards;
}

@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {
	.svg-success .success-tick {
		stroke-dasharray: 0;
		stroke-dashoffset: 0;
		-webkit-animation: none;
		animation: none;
		opacity: 1;
	}

	.svg-success .success-circle-outline {
		stroke-dasharray: 0;
		stroke-dashoffset: 0;
		-webkit-animation: none;
		animation: none;
		opacity: 1;
	}

	.svg-success .success-circle-fill {
		-webkit-animation: none;
		animation: none;
		opacity: 1;
	}
}

.ant-row-middle.headerStyleNew {
	background: #ebedef;
	padding: 7px 10px !important;
	border: 1px solid #ddd;
	margin-bottom: 10px;
}

.ant-picker-date-panel .ant-picker-content th {
	width: 20px;
	height: 20px;
}

.ant-picker-date-panel {
	width: 240px !important;
}

.ant-picker-date-panel .ant-picker-content {
	width: 100% !important;
}

.ant-picker-date-panel .ant-picker-content {
	width: 200px;
}


.ant-picker-date-panel .ant-picker-content th {
	width: 20px !important;
}

.ant-picker-date-panel .ant-picker-content th {
	line-height: 20px;
	font-size: 12px;
}

.ant-picker-date-panel * {
	font-size: 12px;
}

.ant-picker-dropdown {
	z-index: 999999 !important;
}

.ant-btn:hover {
	color: #fff !important;
	border-color: #3c4b64 !important;
	background: #3c4b64 !important;
}

.list-item-device.list-item-device-smaller.disabled {
	background: #dddd;
	opacity: 0.5;
}

.ant-btn.btnLargerSomeStyle {
	height: 45px;
	border-radius: 10px !important;
	font-size: 16px;
}

.ant-btn.btnRounded {
	border-radius: 8px !important;
}

.ant-btn.btnSizeTextSmaller {
	font-size: 13px;
}

.ant-steps-item-icon .ant-steps-icon {
	width: 32px !important;
	height: 32px;
	top: 0 !important;
	display: inline-block;
}

.ant-steps-icon .anticon {
	width: 32px;
	height: 32px;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.ant-steps-icon .anticon svg {
	position: absolute;
	height: 16px;
	margin: auto;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}

.ant-btn.btnBigger {
	padding: 10px 20px !important;
	height: auto;
	min-width: 200px;
}

.list-item-device.list-item-device-smaller {
	padding: 12px;
	margin-bottom: 10px;
}

.list-item-device.list-item-device-smaller .col1Wifi {
	max-width: 60px;
	padding-right: 10px;
}

.list-item-device.list-item-device-smaller .colIcon img {
	max-width: 20px;
}

.list-item-device.list-item-device-smaller .colSerial h4 {
	font-size: 13px !important;
}

.ant-btn.btnSizeTextSmallerMobile {
	font-size: 10px;
}

.ant-btn.ant-btn-default.ant-btn-round.ant-btn-lg.ant-btn-block .anticon.anticon-poweroff {
	position: relative;
	top: -4px;
}

.ant-btn.ant-btn-round.extraLarger {
	width: 200px !important;
	border-radius: 50% !important;
	height: 200px !important;
}

.ant-btn.ant-btn-round.extraLarger .anticon.anticon-poweroff {
	font-size: 80px !important;
}

.acStatus {
	position: absolute;
	right: 30px;
	top: 30px;
	width: 50px;
	height: 20px;
	line-height: 20px;
	border: 1px solid #119f1a;
	color: #119f1a;
	font-size: 11px;
	text-transform: uppercase;
	border-radius: 6px;
}

.emptyHieght {
	background: #f7f7f7;
	padding: 10px;
	border-radius: 8px;
}

.acStatus.powerOff {
	border-color: red;
	color: red;
}

.ant-btn.btnLargerSomeStyle.btnRounded:focus {
	border-color: #3c4b64;
	color: #3c4b64;
}

.ant-btn.btnLargerSomeStyle.btnRounded:focus span {
	color: #3c4b64 !important;
}

.removeWhite {
	background: transparent !important;
}