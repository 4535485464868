@font-face {
    font-family: 'Switzer-Variable';
    src: url('../fonts/Switzer-Variable.woff2') format('woff2'),
         url('../fonts/Switzer-Variable.woff') format('woff'),
         url('../fonts/Switzer-Variable.ttf') format('truetype');
         font-weight: 100 900;
         font-display: swap;
         font-style: normal;
  }
  
  
  /**
  * This is a variable font
  * You can controll variable axes as shown below:
  * font-variation-settings: 'wght' 400.0;
  *
  * available axes:
  
  * 'wght' (range from 100.0 to 900.0)
  
  */
  
  @font-face {
    font-family: 'Switzer-VariableItalic';
    src: url('../fonts/Switzer-VariableItalic.woff2') format('woff2'),
         url('../fonts/Switzer-VariableItalic.woff') format('woff'),
         url('../fonts/Switzer-VariableItalic.ttf') format('truetype');
         font-weight: 100 900;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-Thin.woff2') format('woff2'),
         url('../fonts/Switzer-Thin.woff') format('woff'),
         url('../fonts/Switzer-Thin.ttf') format('truetype');
         font-weight: 100;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-ThinItalic.woff2') format('woff2'),
         url('../fonts/Switzer-ThinItalic.woff') format('woff'),
         url('../fonts/Switzer-ThinItalic.ttf') format('truetype');
         font-weight: 100;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-Extralight.woff2') format('woff2'),
         url('../fonts/Switzer-Extralight.woff') format('woff'),
         url('../fonts/Switzer-Extralight.ttf') format('truetype');
         font-weight: 200;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-ExtralightItalic.woff2') format('woff2'),
         url('../fonts/Switzer-ExtralightItalic.woff') format('woff'),
         url('../fonts/Switzer-ExtralightItalic.ttf') format('truetype');
         font-weight: 200;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-Light.woff2') format('woff2'),
         url('../fonts/Switzer-Light.woff') format('woff'),
         url('../fonts/Switzer-Light.ttf') format('truetype');
         font-weight: 300;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-LightItalic.woff2') format('woff2'),
         url('../fonts/Switzer-LightItalic.woff') format('woff'),
         url('../fonts/Switzer-LightItalic.ttf') format('truetype');
         font-weight: 300;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-Regular.woff2') format('woff2'),
         url('../fonts/Switzer-Regular.woff') format('woff'),
         url('../fonts/Switzer-Regular.ttf') format('truetype');
         font-weight: 400;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-Italic.woff2') format('woff2'),
         url('../fonts/Switzer-Italic.woff') format('woff'),
         url('../fonts/Switzer-Italic.ttf') format('truetype');
         font-weight: 400;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-Medium.woff2') format('woff2'),
         url('../fonts/Switzer-Medium.woff') format('woff'),
         url('../fonts/Switzer-Medium.ttf') format('truetype');
         font-weight: 500;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-MediumItalic.woff2') format('woff2'),
         url('../fonts/Switzer-MediumItalic.woff') format('woff'),
         url('../fonts/Switzer-MediumItalic.ttf') format('truetype');
         font-weight: 500;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-Semibold.woff2') format('woff2'),
         url('../fonts/Switzer-Semibold.woff') format('woff'),
         url('../fonts/Switzer-Semibold.ttf') format('truetype');
         font-weight: 600;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-SemiboldItalic.woff2') format('woff2'),
         url('../fonts/Switzer-SemiboldItalic.woff') format('woff'),
         url('../fonts/Switzer-SemiboldItalic.ttf') format('truetype');
         font-weight: 600;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-Bold.woff2') format('woff2'),
         url('../fonts/Switzer-Bold.woff') format('woff'),
         url('../fonts/Switzer-Bold.ttf') format('truetype');
         font-weight: 700;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-BoldItalic.woff2') format('woff2'),
         url('../fonts/Switzer-BoldItalic.woff') format('woff'),
         url('../fonts/Switzer-BoldItalic.ttf') format('truetype');
         font-weight: 700;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-Extrabold.woff2') format('woff2'),
         url('../fonts/Switzer-Extrabold.woff') format('woff'),
         url('../fonts/Switzer-Extrabold.ttf') format('truetype');
         font-weight: 800;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-ExtraboldItalic.woff2') format('woff2'),
         url('../fonts/Switzer-ExtraboldItalic.woff') format('woff'),
         url('../fonts/Switzer-ExtraboldItalic.ttf') format('truetype');
         font-weight: 800;
         font-display: swap;
         font-style: italic;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-Black.woff2') format('woff2'),
         url('../fonts/Switzer-Black.woff') format('woff'),
         url('../fonts/Switzer-Black.ttf') format('truetype');
         font-weight: 900;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'Switzer';
    src: url('../fonts/Switzer-BlackItalic.woff2') format('woff2'),
         url('../fonts/Switzer-BlackItalic.woff') format('woff'),
         url('../fonts/Switzer-BlackItalic.ttf') format('truetype');
         font-weight: 900;
         font-display: swap;
         font-style: italic;
  }